<template>
  <AppPage class="centered">
    <div>
      <router-link v-for="article in articles" :key="article._id" :to="{name: 'blog-edit', params: {articleId: article._id}}">
        {{article.title}}
      </router-link>
      <div>

        <Button class="primary" :to="{name:'blog-create'}" >New Article</Button>
      </div>
    </div>
  </AppPage>
</template>
<script lang="ts">
import Vue from 'vue';
import AppPage from "@/components/organisms/AppPage.vue";
import Button from "@/components/atoms/Button.vue";

export default Vue.extend({
  components: {
    Button,
    AppPage

  },
  computed: {
    articles(){
      return this.$store.getters['articles/all']
    }
  },
  mounted(){
    this.$store.dispatch('articles/load')
  }
});
</script>
<style scoped lang="less">
@import '../less/variables';
@import '../less/mixins';

a{
  margin-bottom: 1em;
}

</style>
